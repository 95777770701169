import { ref } from 'vue';
import { defineStore } from 'pinia';
import { getCurrentUser } from 'aws-amplify/auth';


export default defineStore('user', () => {
  const id = ref('');
  const email = ref('');
  const name = ref('');
  const isAuthenticated = ref(false);

  async function getCurrentAuthenticatedUser() {
    const currentAuthenticatedUser = await getCurrentUser();
    const { userId } = currentAuthenticatedUser;

    id.value = userId;
    email.value = userId;
    isAuthenticated.value = true;

    return currentAuthenticatedUser;
  }

  return {
    id, email, name, isAuthenticated, getCurrentAuthenticatedUser,
  };
});
