<template>
    <v-bottom-navigation elevation="4"
        grow bg-color="secondary" height="65" class="rounded-pill mb-2">
        <v-btn value="search" to="/app/dashboard" class="text-primary text-h6">
            <v-icon size="medium" class="text-h4">mdi-chart-box-outline</v-icon>
            <span class="text-white font-weight-light text-body-1">Dashoboard</span>
        </v-btn>
        <v-btn value="trips" to="/app/interactions" class="text-primary text-h6">
            <v-icon size="medium" class="text-h4">mdi-clock-time-eight-outline</v-icon>
            <span class="text-white font-weight-light text-body-1">Interactions</span>
        </v-btn>
        <v-btn value="profile" to="/app/account" class="text-primary text-h6">
            <v-icon size="medium" class="text-h4">mdi-account</v-icon>
            <span class="text-white font-weight-light text-body-1">Account</span>
        </v-btn>
    </v-bottom-navigation>
</template>
