<template>
    <v-app>
        <background-container class="pa-0 splash h100">
            <v-main>
                <v-row>
                    <header-bar></header-bar>
                </v-row>
                <v-container>
                    <router-view v-slot="{ Component }">
                        <transition name="slide" mode="out-in">
                            <component :is="Component" :key="$route.path"></component>
                        </transition>
                    </router-view>
                    <v-overlay
                        :model-value="settingsStore.pageLoading"
                        class="align-center justify-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                </v-container>
            </v-main>
        </background-container>
    </v-app>
</template>

<script setup>
    import BackgroundContainer from '@/components/BackgroundContainer';
    import HeaderBar from '@/layouts/default/HeaderLayout.vue';
    import useSettingsStore from '@/stores/settings';

    const settingsStore = useSettingsStore();

    const backNavigationHiddenStates = ['Get Started', 'Dashboard'];
    const logoutNavHiddenStates = ['Login', 'Signup', 'Verify Signup']
    function showBackNav(routeName) {
        return backNavigationHiddenStates.indexOf(routeName) == -1;
    }

</script>

<style>
    .slide-enter-active,
    .slide-leave-active {
        transition: opacity 0.2s ease;
    }

    .slide-enter-from,
    .slide-leave-to {
        opacity: 0;
    }
</style>
