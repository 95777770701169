<template>
    <v-app-bar elevation="4" color="primary" height="85">
      <v-row align="center">
          <v-col cols="2">
              <v-btn v-if="showBackNav($route.name)" class="ml-1 text-h4 font-weight-thin" icon="mdi-chevron-left" @click="onClick">
              </v-btn>
          </v-col>
          <v-col cols="8">
              <v-row justify="center">
                  <v-col cols="8">
                      <v-row justify="center">
                          <v-col cols="4">
                              <v-img max-height="80" :src="logo"></v-img>
                          </v-col>
                          <v-col align-self="center" class="font-weight-bold shadow-lighter-1">
                              <div class="text-h5 justify-text-end">Compos</div>
                              <div class="text-h4 mt-0 font-weight-black">MENTIS</div>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </v-col>
          <v-col cols="2" class="text-right" align-self="center">
                <v-btn icon="mdi-logout" title="Logout"
                  v-show="showLogout($route.name)" @click="signOutUser"></v-btn>
            </v-col>
      </v-row>
  </v-app-bar>
</template>

<script setup>
import logo from '@/assets/logo.png';
import router from '@/router';
import { signOut } from 'aws-amplify/auth';
import useUserStore from '@/stores/user';

const backNavigationHiddenStates = ['Get Started', 'Dashboard', 'Interactions', 'Account'];
const logoutNavHiddenStates = ['Get Started', 'Login', 'Signup', 'Verify Signup'];
function showBackNav(routeName) {
  return backNavigationHiddenStates.indexOf(routeName) === -1;
}

function showLogout(routeName) {
  return logoutNavHiddenStates.indexOf(routeName) === -1;
}

function onClick() {
  router.go(-1);
}

async function signOutUser() {
  const userStore = useUserStore();

  try {
    await signOut();

    userStore.isAuthenticated = false;
    userStore.id = undefined;
    userStore.email = undefined;
    userStore.phone = undefined;

    router.push({ name: 'Get Started' });
  } catch (error) {
    console.log('error signing out: ', error);
    router.push({ name: 'Get Started' });
  }
}
</script>
