<template>
    <v-app>
        <background-container class="pa-0" :splash="['Trips'].indexOf($route.name) == -1" :h100="['Book a Ride', 'Parking at Vertiport','Search Results'].indexOf($route.name) !== -1">
            <v-main>
                <v-row>
                    <header-bar></header-bar>
                </v-row>
                <v-container>
                    <router-view v-slot="{ Component }">
                        <transition name="slide" mode="out-in">
                            <component :is="Component" :key="$route.path"></component>
                        </transition>
                    </router-view>
                </v-container>
                
                <v-overlay :model-value="settingsStore.pageLoading"
                    class="align-center justify-center">
                    <v-progress-circular color="primary" indeterminate size="64" >
                    </v-progress-circular>
                </v-overlay>
            </v-main>
            <footer-bar></footer-bar>
        </background-container>

    </v-app>
</template>

<script setup>
import BackgroundContainer from '@/components/BackgroundContainer';
import useSettingsStore from '@/stores/settings';
import HeaderBar from './HeaderLayout.vue';
import FooterBar from './FooterLayout.vue';
// import { getBreadCrumbs } from '@/helpers';

const settingsStore = useSettingsStore();
</script>

<style>
    .slide-enter-active,
    .slide-leave-active {
        transition: opacity 0.2s ease;
    }

    .slide-enter-from,
    .slide-leave-to {
        opacity: 0;
    }
</style>
