import { createRouter, createWebHashHistory } from 'vue-router';
import useUserStore from '@/stores/user';

import RootLayout from '../layouts/root/RootLayout.vue';
import MainLayout from '../layouts/default/MainLayout.vue';

const unauthenticatedStates = ['Get Started', 'Login', 'Login Details', 'Signup', 'Verify Signup'];

function isAuthenticated() {
  const userStore = useUserStore();

  if (userStore.isAuthenticated) {
    return { name: 'Dashboard' };
  }
}

async function checkForAuth(to) {
  const userStore = useUserStore();

  try {
    await userStore.getCurrentAuthenticatedUser();

    const { redirectedFrom } = to;
    return (!redirectedFrom || unauthenticatedStates.concat(['']).indexOf(redirectedFrom.name) != -1) ? { name: 'Dashboard' } : to.redirectedFrom;
  } catch (error) {
    if (unauthenticatedStates.indexOf(to.name) == -1) {
      return { name: 'Get Started' };
    }
  }
}

async function beforeEnterAppGuard(to) {
  const userStore = useUserStore();
  if (!userStore.isAuthenticated) {
    return { name: 'Get Started' };
  }
}

const routes = [
  {
    path: '/',
    name: 'splash',
    beforeEnter: checkForAuth,
    component: RootLayout,
    children: [
      {
        path: '/home',
        name: 'Get Started',
        beforeEnter: isAuthenticated,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/root/GetStarted.vue'),
      },
      {
        path: '/login',
        name: 'Login',
        beforeEnter: isAuthenticated,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/root/LoginView.vue'),
      },
      {
        path: '/signup',
        name: 'Signup',
        beforeEnter: isAuthenticated,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/root/SignupView.vue'),
      },
      {
        path: '/signup/verify',
        name: 'Verify Signup',
        beforeEnter: isAuthenticated,
        component: () => import(/* webpackChunkName: "signup" */ '@/views/root/SignupVerifyView.vue')
      },
    ],
  },
  {
    path: '/app',
    name: 'app',
    component: MainLayout,
    beforeEnter: beforeEnterAppGuard,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        beforeEnter: beforeEnterAppGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/DashboardView.vue'),
      },
      {
        path: 'interactions',
        name: 'Interactions',
        beforeEnter: beforeEnterAppGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/InteractionsView.vue'),
      },
      {
        path: 'account',
        name: 'Account',
        beforeEnter: beforeEnterAppGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/AccountView.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
