<script setup>
import s1 from '@/assets/splash1.jpg';
import s2 from '@/assets/splash2.jpg';
import s3 from '@/assets/splash3.jpg';
import s4 from '@/assets/splash4.jpg';

defineProps(['splash', 'h100']);

const heroImageList = [s1, s2, s3, s4];
function getHeroImage() {
  return heroImageList[Math.floor(Math.random() * heroImageList.length)];
}

const image = getHeroImage();

</script>

<template>
    <v-container fluid class="pa-0">
        <v-parallax class="h-minimum-screen h-100" :src="image">
            <div class="bg-transparent">
                <slot />
            </div>
        </v-parallax>
    </v-container>
</template>

<style>
.h-minimum-screen {
    min-height: 100dvh;
}
</style>
