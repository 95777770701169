/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:300c0210-f4e8-4317-bccf-e082f2126e21",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_diAPm9C9z",
    "aws_user_pools_web_client_id": "1ubccpho99mm1q16fe6fjgb40i",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://wx2aht7cfvbs5hlf7ttljlj3ta.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yhtmas36j5bszd2tlocfp7hpli",
    "aws_content_delivery_bucket": "bmparentsui-20240403165730-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d38wbk7baq2y3e.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "bm-scoringtable",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
